$(document).ready(function() {

	if (!(window.ActiveXObject) && 'ActiveXObject' in window) {
		console.log('IE11');
		$('#browser-notice').removeClass('hidden');
	}

	if ($('html').is('.lt-ie9')) {
		console.log('IE9 or lower');
		$('#browser-notice').removeClass('hidden');
	}

	function init() {
		$('#companyform').validate({
			rules: {
				adres: {
					required: true
				},
				huisnummer: {
					required: true
				},
				postcode: {
					required: true
				},
				plaats: {
					required: true
				},
				telefoon: {
					required: true
				},
				website: {
					required: true
				},
				email: {
					required: true,
					email: true
				},
				userfile: {
					required: true,
					accept: 'image/*',
					extensions: 'png|jpg|gif'
				}
			},
			messages: {
				adres: {
					required: 'Vul een adres in'
				},
				huisnummer: {
					required: '*123'
				},
				postcode: {
					required: 'Vul een postcode in'
				},
				plaats: {
					required: 'Vul een plaatsnaam in'
				},
				telefoon: {
					required: 'Vul een geldig telefoonnummer in'
				},
				website: {
					required: 'Vul een geldig url in'
				},
				email: {
					required: 'Vul een geldig email adres in'
				}
			},
			errorPlacement: function(error, element) {
				element.attr('placeholder', error[0].outerText);
			},
			submitHandler: function(form) {
				form.submit();
			}
		});

		$('#companyform').on('submit', sendGAEvent);

		$('#brochure-selection').on('change', function(e) {
			setPdfPreview(this.value)
		})
	}

	function setPdfPreview(file) {
		$('#brochure-preview').html('<embed src="pdf/' + file + '.pdf" width="580" height="820" id="pdf-preview" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html">');
	}

	function sendGAEvent() {
		var klantvalue = $('input[name=klantnummer]').val();
		if ($('#companyform').valid() == true) {
			ga('send', 'event', 'Download', 'submit', 'Genereer PDF', klantvalue);
		}
	}

	init();

});
